





































































































import { Vue, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { HbTimeSlot, HbType } from '../../types/hybrid'

@Component
export default class extends Vue {
  private onFetch = true
  private timeSlots: Partial<HbTimeSlot>[] = []
  private headers = [
    { text: 'Subject', value: 'subject' },
    { text: 'Day', value: 'dayOfWeek' },
    { text: 'Start', value: 'startTime' },
    { text: 'Duration', value: 'duration' },
    { text: 'Type', value: 'type' },
    { text: 'Capacity', value: 'capacity' },
    { text: 'Active', value: 'isActive' },
  ]
  private dialog = false
  private subjects = [
    { text: 'Math', value: 'math' },
    { text: 'Physics', value: 'physics' },
    { text: 'Chem', value: 'chem' },
    { text: 'English', value: 'english' },
    { text: 'Sci', value: 'science' },
  ]
  private subject = 'math'
  private hbTypes = [HbType.ehb, HbType.fhb]
  private hbType = HbType.ehb
  private days = [
    { text: 'Monday', value: 1 },
    { text: 'Tuesday', value: 2 },
    { text: 'Wednesday', value: 3 },
    { text: 'Thursday', value: 4 },
    { text: 'Friday', value: 5 },
    { text: 'Saturday', value: 6 },
    { text: 'Sunday', value: 0 },
  ]
  private day = 1
  private startTimes = [8, 9, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20]
  private startTime = 8
  private duration = '120'
  private capacity = '20'
  private onLoad = false

  mounted() {
    this.fetchHybrid()
  }

  private async fetchHybrid() {
    try {
      const { data } = await this.$apollo.query<{
        hbTimeSlots: Partial<HbTimeSlot>[]
      }>({
        query: gql`
          query FetchAllHybrid {
            hbTimeSlots {
              id
              dayOfWeek
              startTime
              duration
              type
              capacity
              subject
              isActive
            }
          }
        `,
        fetchPolicy: 'no-cache',
      })
      if (data) {
        this.timeSlots = data.hbTimeSlots
      }
    } catch (error) {
      // empty
    } finally {
      this.onFetch = false
    }
  }

  private submit() {
    this.addNewHybrid()
  }

  private async addNewHybrid() {
    this.onLoad = true
    try {
      const { data } = await this.$apollo.mutate<{
        createHbTimeSlot: Partial<HbTimeSlot>
      }>({
        mutation: gql`
          mutation CreateNewHybrid($data: CreateHbTimeSlotInput!) {
            createHbTimeSlot(data: $data) {
              id
              dayOfWeek
              startTime
              duration
              type
              capacity
              subject
              isActive
            }
          }
        `,
        variables: {
          data: {
            dayOfWeek: this.day,
            startTime: this.startTime,
            duration: +this.duration,
            type: this.hbType,
            capacity: +this.capacity,
            subject: this.subject,
          },
        },
      })
      if (data) {
        this.timeSlots.push(data.createHbTimeSlot)
      }
    } catch (error) {
      // empty
    } finally {
      this.dialog = false
      this.onLoad = false
    }
  }
}
