import { User } from './user'

export enum HbType {
  ehb = 'Ehb',
  fhb = 'Fhb',
}

export interface HbTimeTable {
  id: number
  dateTime: string
  capacity: number
  isActive: boolean
  timeSlot: Partial<HbTimeSlot>
  reservedTimeTables: Partial<ReservedTimeTable>[]
}

export interface ReservedTimeTable {
  id: number
  isActive: boolean
  createdAt: string
  defaultTimeTable: Partial<HbTimeTable>
  user: Partial<User>
}

export interface HbTimeSlot {
  id: number
  dayOfWeek: number
  startTime: number
  duration: number
  type: HbType
  capacity: number
  subject: string
  isActive: boolean
  timeTables: Partial<HbTimeTable>[]
}
